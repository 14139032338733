// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-commot-js": () => import("./../../../src/pages/portfolio/commot.js" /* webpackChunkName: "component---src-pages-portfolio-commot-js" */),
  "component---src-pages-portfolio-crime-climate-population-js": () => import("./../../../src/pages/portfolio/crime-climate-population.js" /* webpackChunkName: "component---src-pages-portfolio-crime-climate-population-js" */),
  "component---src-pages-portfolio-fam-js": () => import("./../../../src/pages/portfolio/fam.js" /* webpackChunkName: "component---src-pages-portfolio-fam-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-movie-ratings-js": () => import("./../../../src/pages/portfolio/movie-ratings.js" /* webpackChunkName: "component---src-pages-portfolio-movie-ratings-js" */),
  "component---src-pages-portfolio-project-momo-js": () => import("./../../../src/pages/portfolio/project-momo.js" /* webpackChunkName: "component---src-pages-portfolio-project-momo-js" */),
  "component---src-pages-portfolio-sabay-website-js": () => import("./../../../src/pages/portfolio/sabay-website.js" /* webpackChunkName: "component---src-pages-portfolio-sabay-website-js" */),
  "component---src-pages-portfolio-science-world-js": () => import("./../../../src/pages/portfolio/science-world.js" /* webpackChunkName: "component---src-pages-portfolio-science-world-js" */),
  "component---src-pages-portfolio-ssn-website-js": () => import("./../../../src/pages/portfolio/ssn-website.js" /* webpackChunkName: "component---src-pages-portfolio-ssn-website-js" */),
  "component---src-pages-portfolio-swapp-js": () => import("./../../../src/pages/portfolio/swapp.js" /* webpackChunkName: "component---src-pages-portfolio-swapp-js" */),
  "component---src-pages-portfolio-udgl-js": () => import("./../../../src/pages/portfolio/udgl.js" /* webpackChunkName: "component---src-pages-portfolio-udgl-js" */),
  "component---src-pages-portfolio-whatsapp-conceptual-design-js": () => import("./../../../src/pages/portfolio/whatsapp-conceptual-design.js" /* webpackChunkName: "component---src-pages-portfolio-whatsapp-conceptual-design-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

